import React from "react"
import styled from "styled-components"
import LightImg from "../../assets/images/cog.svg"

const Lightsection = () => {
  return (
    <>
      <SectionDevider></SectionDevider>
      <LightSvg>
        <DivStyle className="text-center">
          <H2Style>Managing Amazon Business Doesn’t Have to Be Hard.</H2Style>
          <PStyled>
            Get things done as smooth and quick as possible, you do not have to
            do the same thing every day. Let azszero help you to plan
            everything.
          </PStyled>
        </DivStyle>
        <LightDivider></LightDivider>
      </LightSvg>
    </>
  )
}

const DivStyle = styled.div`
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 600px;
`
const H2Style = styled.h2`
  font-weight: 800;
  font-size: 35px;
  line-height: 1.3em;
  color: white;
  padding-bottom: 10px;
`

const PStyled = styled.p`
  line-height: 1.8em;
  color: midnightblue;
  font-weight: bold;
`

const LightDivider = styled.div`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0xMjgwIDE0MFYwUzk5My40NiAxNDAgNjQwIDEzOSAwIDAgMCAwdjE0MHoiLz48L2c+PC9zdmc+);
  background-size: 100% 100px;
  bottom: 0;
  height: 100px;
  z-index: 1;
  background-position: center;
  background-size: cover;
  width: 100%;
`

const LightSvg = styled.div`
  background-blend-mode: luminosity;
  background-image: url(${LightImg}),
    linear-gradient(135deg, #53a0fd 0%, #4161d4 100%) !important;
  padding-top: 100px;
  padding-right: 0px;
  padding-left: 0px;
  background-color: inherit !important;
  background-position: center;
  background-size: cover;
`

const SectionDevider = styled.div`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIzNjVweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiMxYjYyYzIiPjxwYXRoIGQ9Ik0wIDE0MGgxMjgwQzU3My4wOCAxNDAgMCAwIDAgMHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAxNDBoMTI4MEM1NzMuMDggMTQwIDAgMzAgMCAzMHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMCAxNDBoMTI4MEM1NzMuMDggMTQwIDAgNjAgMCA2MHoiLz48L2c+PC9zdmc+);
  background-size: 100% 365px;
  bottom: 0;
  height: 365px;
  z-index: 1;
  margin-top: -365px;
`

export default Lightsection
